<!-- src/views/components/ComponentModal.vue -->
<template>
  <v-dialog v-model="isModalOpen" max-width="1100px" data-cy="edit-modal" persistent>
    <v-card>
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ title }}</span>
        <v-icon small @click="close" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <h4>Basic data</h4>
        <v-form ref="form" class="scrollable-modal" data-cy="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field label="Name" v-model="currentItem.name" variant="underlined" data-cy="input-name"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field label="Description" v-model="currentItem.description" variant="underlined" data-cy="input-description"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                label="Product Family Code"
                :items="productFamilyOptions"
                v-model="currentItem.productFamilyCode"
                variant="underlined"
                item-title="label"
                data-cy="select-product-family"
                clearable
                return-object
              ></v-autocomplete>
            </v-col>
            <v-btn
              class="btn-orange"
              style="margin-top: 24px; margin-left: 16px"
              @click="loadAttributesFromProductFamily"
              :disabled="!currentItem.productFamilyCode"
              data-cy="load-attributes-btn"
            >
              Load Attributes
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>

      <!-- <pre>{{ currentItem }}</pre> -->
      <v-card-text>
        <v-data-table :headers="containsHeaders" :items="currentItem.contains" item-value="type" class="elevation-1 custom-table" data-cy="contains-table">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Contains</v-toolbar-title>
              <v-col class="d-flex align-center" cols="auto">
                <v-autocomplete
                  label="Select Logic Module"
                  :items="availableComponents"
                  v-model="selectedComponentType"
                  variant="underlined"
                  data-cy="select-component-type"
                  clearable
                  style="width: 200px"
                  item-title="name"
                  return-object
                  :menu-props="{ maxHeight: '250px' }"
                ></v-autocomplete>
                <v-btn class="btn-orange ml-2" @click="addContainsItem" data-cy="add-contains-btn">
                  <v-icon left>mdi-plus</v-icon>
                  Add
                </v-btn>
              </v-col>
            </v-toolbar>
          </template>

          <template v-slot:item.name="{ item }">
            <div style="position: relative">
              {{ truncateText(item.name, 20) }}

              <span style="position: absolute; right: 0">
                <v-tooltip activator="parent" location="top">Copy ID</v-tooltip>
                <span class="icon copy-btn" @click="handleCopy(item.componentTypeId || item.id)"><i class="fa-duotone fa-solid fa-copy"></i></span>
              </span>
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ truncateText(item.description, 20) }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.status === 'OK' ? 'green' : 'red'" dark>
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:item.quantity_formula="{ item }">
            <v-btn class="formula-btn" small @click="openFormulaModal(item, 'contain')" data-cy="edit-formula">
              <!-- {{ item.qty?.body }} -->
              <!-- {{ item.qty?.body ? (renderFormula(item.qty.body).length > 25 ? renderFormula(item.qty.body).slice(0, 25) + '...' : renderFormula(item.qty.body)) : 'ADD FORMULA' }} -->
              {{
                item.qty?.body !== null && item.qty?.body !== undefined
                  ? renderFormula(item.qty.body).length > 25
                    ? renderFormula(item.qty.body).slice(0, 25) + '...'
                    : renderFormula(item.qty.body)
                  : 'Add formula'
              }}
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon color="red" class="pl-4" small @click="removeContainsItem(item)" data-cy="delete-contains-icon">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <!-- Attributes Section -->

      <v-card-text>
        <v-data-table :headers="attributesHeaders" :items="currentItem.attributes" item-value="name" class="elevation-1 custom-table" data-cy="attributes-table">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Attributes</v-toolbar-title>
              <v-col class="d-flex align-center" cols="auto">
                <v-autocomplete
                  label="Select Attribute"
                  :items="availableAttributes_trimmed"
                  v-model="selectedAttribute"
                  variant="underlined"
                  data-cy="select-attribute"
                  clearable
                  style="width: 200px"
                  item-title="name"
                  return-object
                  :menu-props="{ maxHeight: '250px' }"
                ></v-autocomplete>
                <v-btn class="btn-orange ml-2" @click="addAttribute" data-cy="add-attributes-btn">
                  <v-icon left>mdi-plus</v-icon>
                  Add
                </v-btn>
              </v-col>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div style="position: relative">
              {{ item.name }}

              <span style="position: absolute; right: 0">
                <v-tooltip activator="parent" location="top">Copy ID</v-tooltip>
                <span class="icon copy-btn" @click="handleCopy(item.id)"><i class="fa-duotone fa-solid fa-copy"></i></span>
              </span>
            </div>
          </template>

          <template v-slot:item.unit="{ item }">
            {{ getUomNameById(item.uomId) }}
          </template>

          <template v-slot:item.value="{ item }">
            <v-btn class="formula-btn" small @click="openFormulaModal(item, 'attribute')">
              {{
                item.value?.body !== null && item.value?.body !== undefined
                  ? renderFormula(item.value.body).length > 25
                    ? renderFormula(item.value.body).slice(0, 25) + '...'
                    : renderFormula(item.value.body)
                  : 'Add formula'
              }}
            </v-btn>
          </template>

          <template v-slot:item.comparison="{ item }">
            <v-select v-model="item.comparison" :items="['Ignore', 'Equals', 'Equals/Create']" variant="underlined" placeholder="Equals"></v-select>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="red" class="pl-4" small @click="removeAttribute(item)" data-cy="delete-attribute-icon">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="close" data-cy="cancel-btn">Cancel</v-btn>
        <v-btn class="btn-save" @click="save" data-cy="save-btn">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <FormulaModal v-if="isFormulaModalOpen" :type="formulaModalType" :item="currentPushItem ?? undefined" @close="closeFormulaModal" @save="saveFormula" />

  <Dialogs :cancelDialog="cancelDialog" @closeCancelDialog="closeCancelDialog" @confirmCancel="confirmCancel" />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import FormulaModal from './FormulaModal.vue';
  import { ComponentItem, AttributeItem, ContainsItem, ProductFamilyCodeItem } from '@/types/components';
  import lodash from 'lodash';
  import { copyToClipboard } from '@/services/clipboard';
  import Dialogs from '@/components/Dialogs.vue';

  export default defineComponent({
    components: {
      FormulaModal,
      Dialogs,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      currentItem: {
        type: Object as PropType<ComponentItem>,
        required: true,
        default: () => ({
          type: '',
          name: '',
          components: 0,
          description: '',
          noMaterials: 0,
          status: '',
          series: '',
          inheritFrom: [],
          image: '',
          contains: [],
          attributes: [],
          productFamilyCode: '',
        }),
      },
      productFamilyOptions: {
        type: Array as PropType<ProductFamilyCodeItem[]>,
        required: true,
        default: () => [],
      },
      availableAttributes: {
        type: Array as PropType<AttributeItem[]>,
        required: true,
        default: () => [],
      },
      availableComponents: {
        type: Array as PropType<ComponentItem[]>,
        required: true,
        default: () => [],
      },
      availableUom: {
        type: Array as PropType<any[]>,
        required: true,
        default: () => [],
      },
    },
    emits: ['close', 'save'],
    data() {
      return {
        isModalOpen: true,
        isFormulaModalOpen: false,
        currentPushItem: undefined as ContainsItem | undefined,
        currentAttributeItem: undefined as AttributeItem | undefined,
        selectedComponentType: null as ComponentItem | null,
        componentTypeOptions: ['Type1', 'Type2', 'Type3'],
        containsHeaders: [
          { title: 'Logic Module', value: 'name', width: '30%' },
          { title: 'Description', value: 'description', width: '40%' },
          { title: 'Quantity', value: 'quantity_formula', width: '25%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '5%' },
        ],
        attributesHeaders: [
          { title: 'Name', value: 'name', width: '20%' },
          { title: 'Units', value: 'unit', width: '5%' },
          { title: 'Description', value: 'description', width: '20%' },
          { title: 'Value Logic', value: 'value', width: '20%' },
          { title: 'Comparison Logic', value: 'comparison', width: '20%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '5%' },
        ],
        selectedAttribute: null as AttributeItem | null,
        inheritOptions: [] as { id: string | undefined; name: string }[],
        formulaModalType: '',
        originalItem: lodash.cloneDeep(this.currentItem),
        cancelDialog: false,
      };
    },
    watch: {
      currentItem: {
        handler(currentComponent: ComponentItem) {
          this.genInheritOptions(currentComponent);
          this.populateAttributes();
          this.updateInheritFrom();
          this.populateContains();
          this.populateFamily();
        },
        immediate: true,
      },
    },
    computed: {
      availableAttributes_trimmed() {
        return this.availableAttributes.map((component) => ({
          ...component,
          name: component.name.length > 25 ? component.name.substring(0, 25) + '...' : component.name,
        }));
      },
    },
    methods: {
      renderFormula(formula: any): string {
        switch (typeof formula) {
          case 'number':
            return formula.toString();

          case 'string': {
            if (formula.includes('-')) {
              const valueParts = formula.split('-');
              const attribute = this.availableAttributes?.find((attr: any) => attr.id === valueParts[0]);
              if (attribute) {
                const selectedValue = attribute.values?.find((val: any) => val.id === valueParts[1]);
                return selectedValue ? `${attribute.name}: ${selectedValue.name || selectedValue.value}` : `${attribute.name}: Unknown Value`;
              }
            }
            const matchedAttribute = this.availableAttributes?.find((attr: any) => attr.id === formula);
            return matchedAttribute ? matchedAttribute.name : formula;
          }

          case 'object': {
            if (formula !== null) {
              if ('function' in formula && typeof formula.function === 'string') {
                const func = formula.function;
                const inputs = Object.keys(formula)
                  .filter((key) => key !== 'function' && key.startsWith('input'))
                  .map((key) => this.renderFormula(formula[key]));
                return `${func}(${inputs.join(', ')})`;
              }

              if ('value' in formula && typeof formula.value === 'number') {
                return formula.value.toString();
              }

              console.warn('Object is not a valid formula or value object:', formula);
              return '';
            }

            console.warn('Null object provided as formula:', formula);
            return '';
          }

          default:
            console.warn('Unexpected formula type:', typeof formula, formula);
            return 'Unsupported Formula Type';
        }
      },
      renderPart(part: any): string {
        if (typeof part === 'number') {
          return part.toString();
        } else if (typeof part === 'string') {
          const matchedAttribute = this.availableAttributes.find((attr: any) => attr.id === part);
          return matchedAttribute ? matchedAttribute.name : part;
        } else if (typeof part === 'object' && part !== null) {
          if ('value' in part && typeof part.value === 'number') {
            return part.value.toString();
          } else if ('function' in part) {
            return this.renderFormula(part);
          } else {
            console.warn('Object is not a valid formula or value object:', part);
            return '';
          }
        } else {
          console.warn('Unexpected part type:', typeof part, part);
          return '';
        }
      },

      getUomNameById(uomId: any) {
        for (const category of this.availableUom) {
          const unit = category.value?.find((u: any) => u.id === uomId);
          if (unit) {
            return unit.name;
          }
        }
        return '';
      },

      genInheritOptions(currentComponent: ComponentItem) {
        this.inheritOptions = this.availableComponents
          .filter((component: ComponentItem) => component.name !== currentComponent.name)
          .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically by name
          .map((component: ComponentItem) => ({
            id: component.id,
            name: component.name,
          }));
      },
      updateInheritFrom() {
        const inheritsFromArray = Array.isArray(this.currentItem.inheritsFrom)
          ? this.currentItem.inheritsFrom
          : typeof this.currentItem.inheritsFrom === 'string'
            ? JSON.parse(this.currentItem.inheritsFrom)
            : [];

        this.currentItem.inheritFrom = inheritsFromArray
          .map((inheritId: string) => {
            const matchedOption = this.inheritOptions.find((option) => option.id === inheritId);
            return matchedOption ? { id: matchedOption.id, name: matchedOption.name } : null;
          })
          .filter(Boolean);
      },

      populateAttributes() {
        this.currentItem.attributes = this.currentItem.attributes.map((attribute) => {
          const matchedAttribute = this.availableAttributes.find((attr) => attr.id === attribute.attributeId);
          if (matchedAttribute) {
            const value = attribute.value;
            return value !== undefined ? { ...matchedAttribute, value, comparison: attribute.comparison } : { ...matchedAttribute, comparison: attribute.comparison };
          }
          return attribute;
        });
      },

      populateContains() {
        this.currentItem.contains = this.currentItem.contains.map((containsItem) => {
          const matchedContainsItem = this.availableComponents.find((component) => component.id === containsItem.componentTypeId);
          if (matchedContainsItem) {
            return {
              ...containsItem,
              ...matchedContainsItem,
              qty: containsItem.qty || matchedContainsItem.qty,
            };
          }
          return containsItem;
        });
      },

      addContainsItem() {
        if (this.selectedComponentType) {
          if (!Array.isArray(this.currentItem.contains)) {
            this.currentItem.contains = [];
          }

          const newContainsItem: ContainsItem = {
            id: this.selectedComponentType.id,
            name: this.selectedComponentType.name,
            type: this.selectedComponentType.type,
            components: this.selectedComponentType.components,
            description: this.selectedComponentType.description,
            noMaterials: this.selectedComponentType.noMaterials,
            status: this.selectedComponentType.status,
            quantity_formula: this.selectedComponentType.quantity_formula,
            qty: this.selectedComponentType.contains[0]?.qty,
          };

          this.currentItem.contains.push(newContainsItem);
          this.selectedComponentType = null;
        }
      },

      populateFamily() {
        const matchedFamily = this.productFamilyOptions.find((family) => family.id === this.currentItem.productFamily?.id);

        if (matchedFamily) {
          this.currentItem.productFamilyCode = {
            id: matchedFamily.id,
            code: matchedFamily.code,
            label: matchedFamily.label,
            segment: matchedFamily.segment,
          };
        } else {
          this.currentItem.productFamilyCode = undefined;
        }
      },

      openFormulaModal(item: any, type: string) {
        if (type === 'contain') {
          this.currentPushItem = item as ContainsItem;
        } else if (type === 'attribute') {
          this.currentPushItem = {
            id: '',
            name: '',
            type: '',
            components: 0,
            description: '',
            noMaterials: 0,
            status: '',
            quantity_formula: '',
            componentTypeId: '',
            qty: undefined,
            ...item,
          };
        }
        this.formulaModalType = type;
        this.isFormulaModalOpen = true;
      },

      editContainsItem(item: ContainsItem) {
        // Implementation for editing contains item
      },

      removeContainsItem(item: ContainsItem) {
        if (this.currentItem) {
          this.currentItem.contains = this.currentItem.contains.filter((contains: ContainsItem) => contains !== item);
        }
      },

      truncateText(value: string | undefined, length: number): string {
        if (!value) return '';
        if (value.length <= length) return value;
        return value.substring(0, length) + '...';
      },

      addAttribute() {
        if (this.selectedAttribute) {
          this.currentItem.attributes.push({ ...this.selectedAttribute });
          this.selectedAttribute = null;
        }
      },
      loadAttributesFromProductFamily() {
        const productFamily = this.currentItem.productFamilyCode;
        const list = productFamily?.attributes;

        if (!this.currentItem.attributes) {
          this.currentItem.attributes = [];
        }

        let addedCount = 0;

        list?.forEach((attribute: any) => {
          const matchedAttribute = this.availableAttributes.find((attr) => attr.id === attribute);
          if (matchedAttribute) {
            if (!this.currentItem.attributes.some((attr) => attr.id === matchedAttribute.id)) {
              this.currentItem.attributes.push({ ...matchedAttribute });
              addedCount++;
            }
          }
        });

        const title = addedCount > 0 ? `${addedCount} attribute(s) added` : 'No new attributes added';
        const icon = addedCount > 0 ? 'success' : 'info';

        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: icon,
          title: title,
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });

        console.log('Product Family Attributes:', list);
      },
      editAttribute(item: AttributeItem) {
        // Implementation for editing attribute
      },

      removeAttribute(item: AttributeItem) {
        if (this.currentItem) {
          this.currentItem.attributes = this.currentItem.attributes.filter((attribute: AttributeItem) => attribute !== item);
        }
      },

      handleClose() {
        if (this.hasChanges()) {
          this.cancelDialog = true;
        } else {
          this.closeModal();
        }
      },
      hasChanges(): boolean {
        const compareById = (array1: any[], array2: any[], idKey1: string, idKey2: string): boolean => {
          const ids1 = array1.map((item) => item[idKey1]).sort();
          const ids2 = array2.map((item) => item[idKey2]).sort();
          return lodash.isEqual(ids1, ids2);
        };

        let { productFamilyCode, inheritFrom, attributes: currentAttributes, contains: currentContains, ...currentItemCopy } = lodash.cloneDeep(this.currentItem);
        let {
          productFamilyCode: originalroductFamilyCode,
          inheritFrom: originalInheritFrom,
          attributes: originalAttributes,
          contains: originalContains,
          ...originalItemCopy
        } = lodash.cloneDeep(this.originalItem);

        const attributesEqual = compareById(currentAttributes, originalAttributes, 'id', 'attributeId');
        const containsEqual = compareById(currentContains, originalContains, 'componentTypeId', 'componentTypeId');
        const restEqual = lodash.isEqual(currentItemCopy, originalItemCopy);
        return !(attributesEqual && containsEqual && restEqual);
      },
      close() {
        this.handleClose();
      },
      save() {
        this.$emit('save', this.currentItem);
        this.originalItem = lodash.cloneDeep(this.currentItem);
        this.closeModal();
      },
      closeModal() {
        this.isModalOpen = false;
        this.$emit('close');
      },
      closeCancelDialog() {
        this.cancelDialog = false;
      },
      confirmCancel() {
        this.cancelDialog = false;
        this.closeModal();
      },

      closeFormulaModal() {
        this.isFormulaModalOpen = false;
        this.currentPushItem = undefined;
      },

      saveFormula(updatedData: { type: string; item: any }) {
        const { type, item } = updatedData;

        if (type === 'contain') {
          const index = this.currentItem.contains.findIndex((containItem) => containItem.id === item.id);

          if (index !== -1) {
            if (!this.currentItem.contains[index].qty) {
              this.currentItem.contains[index].qty = {
                id: '',
                description: '',
                statiname: '',
                body: '',
              };
            }

            if (!this.currentItem.contains[index].qty.body) {
              this.currentItem.contains[index].qty.body = {};
            }

            this.currentItem.contains[index].qty.body = item.body;
          }
        } else if (type === 'attribute') {
          const index = this.currentItem.attributes.findIndex((attrItem) => attrItem.id === item.id);

          if (index !== -1) {
            if (!this.currentItem.attributes[index].value) {
              this.currentItem.attributes[index].value = {
                id: '',
                description: '',
                body: '',
              };
            }

            if (!this.currentItem.attributes[index].value.body) {
              this.currentItem.attributes[index].value.body = {};
            }

            this.currentItem.attributes[index].value.body = item.body;
          }
        }

        this.closeFormulaModal();
      },

      async handleCopy(id: any) {
        try {
          await copyToClipboard(id, this.$swal);
        } catch (error) {
          console.error('Copy operation failed:', error);
        }
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
  div:deep(td) {
    border: none !important;
  }
  div:deep(.v-input__details) {
    padding-top: 0px !important;
    grid-area: auto !important;
  }
  .formula-btn {
    text-transform: none;
  }
  .copy-btn {
    cursor: pointer;
    margin-left: 5px;
  }
  .copy-btn:hover {
    color: orange;
  }
</style>
