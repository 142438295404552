<template>
  <div class="certified-product-families-container">
    <v-data-table :headers="headers" :items="families" class="families-table" data-cy="certified-product-families-table" :loading="loading">
      <template v-slot:item.pnFamily="{ item }">
        <div class="d-flex align-center family-cell" @click="toggleFamily(item.pnFamily)">
          <v-icon size="small" class="mr-2">
            {{ expandedFamilies.includes(item.pnFamily) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
          </v-icon>
          {{ item.pnFamily }}
          <v-chip class="ml-2" size="small" color="primary">{{ formatRange(item.bomCount) }}</v-chip>
        </div>
        <div v-if="expandedFamilies.includes(item.pnFamily)" class="family-details mt-2">
          <v-table density="compact" class="nested-table">
            <thead>
              <tr>
                <th class="certification-range">Certification Range</th>
                <th class="part-number">Certified Part Number</th>
                <th class="created-by">Certified By</th>
                <th class="timestamps">Timestamps</th>
                <th class="actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bom in item.boms" :key="bom.id" class="nested-row">
                <td class="certification-range">{{ getCertificationRange(bom) }}</td>
                <td class="part-number">{{ bom.productPn }}</td>
                <td class="created-by">{{ formatUnknown(bom.certificationData!.certifiedBy) }}</td>
                <td class="timestamps">
                  <div class="d-flex flex-column">
                    <span class="text-caption">Created: {{ formatDate(bom.createdAt) }}</span>
                    <span class="text-caption text-grey">Last updated: {{ updateEventformatDate(bom.updatedAt) }}</span>
                  </div>
                </td>
                <td class="actions">
                  <div class="controls-wrapper">
                    <span @click.stop="openModal(bom)">
                      <i class="fa-duotone fa-solid fa-pen-to-square"></i>
                    </span>
                    <span @click.stop="confirmRevoke(bom)">
                      <i class="fa-duotone fa-solid fa-trash"></i>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
      </template>
    </v-data-table>
  </div>
  <Dialogs :revoke-dialog="revokeDialog" @update:revoke-dialog="revokeDialog = $event" @confirmRevoke="handleRevokeConfirm" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Dialogs from '@/components/Dialogs.vue';
  import bomService from '@/services/bomService';

  export default defineComponent({
    name: 'CertifiedProductFamilies',

    components: {
      Dialogs,
    },

    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      families: {
        type: Array as () => {
          pnFamily: string;
          bomCount: number;
          boms: Bom[];
        }[],
        required: true,
      },
    },

    data() {
      return {
        expandedFamilies: [] as string[],
        headers: [
          {
            title: 'Product Families',
            key: 'pnFamily',
            sortable: true,
          },
        ],
        revokeDialog: false,
        itemToRevoke: null as Bom | null,
      };
    },

    methods: {
      toggleFamily(pnFamily: string) {
        const index = this.expandedFamilies.indexOf(pnFamily);
        if (index === -1) {
          this.expandedFamilies.push(pnFamily);
        } else {
          this.expandedFamilies.splice(index, 1);
        }
      },

      formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },

      getCertificationRange(bom: Bom): string {
        if (!bom.certificationData) return 'Not available';
        const certifiedData = bom.certificationData;
        const unitOfMeasurement = certifiedData.rangeUnit.charAt(0).toUpperCase() + certifiedData.rangeUnit.slice(1);
        return `${certifiedData.rangeStart} ${unitOfMeasurement} - ${certifiedData.rangeEnd} ${unitOfMeasurement}`;
      },

      formatRange(count: number): string {
        if (count <= 1) return `${count} Certified Range`;
        return `${count} Certified Ranges`;
      },

      updateEventformatDate(dateString: string): string {
        const date = new Date(dateString);
        const now = new Date();
        if (date.toDateString() === now.toDateString()) {
          return `Today, ${date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })}`;
        }

        return date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
      },

      formatUnknown(user: string) {
        if (user === 'Unknown') return '-';
        return user;
      },

      openModal(bom: Bom) {
        this.$emit('open-modal', 'edit', bom);
      },

      confirmRevoke(bom: any) {
        this.itemToRevoke = bom;
        this.revokeDialog = true;
      },

      async handleRevokeConfirm() {
        if (this.itemToRevoke) {
          try {
            const { updatedAt, createdAt, ...cleanedBom } = this.itemToRevoke;
            delete cleanedBom.certificationData;
            cleanedBom.referenceType = 'reference';

            await bomService.bomUpdate(cleanedBom.id, cleanedBom);

            if (this.itemToRevoke) {
              delete this.itemToRevoke.certificationData;
              this.itemToRevoke.referenceType = 'reference';
            }
            this.$log.showSuccessMessage('BOM certification revoked successfully');
            this.$forceUpdate();
          } catch (error) {
            this.$log.showError(`Failed to revoke BOM certification: ${error}`);
          }
        }
        this.revokeDialog = false;
        this.itemToRevoke = null;
      },
    },
  });
</script>

<style scoped>
  .certified-product-families-container {
    margin-top: 20px;
  }

  .family-cell {
    cursor: pointer;
    padding: 8px 0;
  }

  .family-details {
    background-color: #f8fafc;
    border-radius: 4px;
    margin: 8px 0;
  }

  .nested-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  /* Column width definitions */
  .certification-range {
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .part-number {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .created-by {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .timestamps {
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    width: 10%;
    text-align: center;
  }

  .nested-table th {
    background-color: #f1f5f9;
    color: #475569;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: left;
    padding: 8px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .nested-table td {
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #1e293b;
    border-bottom: 1px solid #e2e8f0;
  }

  .nested-row:hover {
    background-color: #f1f5f9;
  }

  .controls-wrapper {
    padding: 0 8px;
    gap: 15px;
    display: flex;
    font-size: 18px;
  }

  .controls-wrapper span {
    cursor: pointer;
  }

  .controls-wrapper span > i:hover {
    color: orange;
  }

  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }

  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }

  :deep(.v-data-table) {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
  }

  :deep(.v-data-table__wrapper) {
    border-radius: 8px;
  }

  :deep(.v-data-table thead th) {
    font-weight: 600 !important;
    color: #475569 !important;
    font-size: 0.875rem !important;
    text-transform: none !important;
  }

  /* Add ellipsis for overflowing text in all cells */
  .nested-table td,
  .nested-table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
