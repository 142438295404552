<!-- src/views/assemblies/AssembliesModal.vue -->
<template>
  <v-dialog v-model="isModalOpen" max-width="1200px" data-cy="edit-modal" persistent>
    <v-card>
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ title }}</span>
        <v-icon small @click="close" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <!-- <h4>Basic data</h4> -->
        <v-form ref="form" class="scrollable-modal" data-cy="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field label="Name" v-model="currentItem.name" variant="underlined" data-cy="input-name"></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field label="Description" v-model="currentItem.description" variant="underlined" data-cy="input-description"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-select
                label="Assembly Type"
                :items="typeOptions"
                v-model="selectedType"
                variant="underlined"
                item-title="label"
                item-value="value"
                @update:modelValue="handleTypeChange"
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                label="Code"
                v-if="currentItem.type == 'blueprint' || currentItem.type == 'values'"
                v-model="currentItem.code"
                variant="underlined"
                data-cy="input-code"
              ></v-text-field>
              <v-select
                label="Assembly Base Product"
                :items="assemblyBaseProductOptions"
                v-if="currentItem.type == 'assembly'"
                v-model="assemblyBaseProduct"
                variant="underlined"
                item-title="label"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8" v-if="currentItem.type == 'blueprint'">
              <v-row class="d-flex align-center">
                <v-col cols="12" md="5">
                  <v-text-field label="Base PN" v-model="currentItem.baseProduct" variant="underlined" data-cy="input-base-product" :loading="loadingGenerateData"></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Customer PN"
                    v-model="currentItem.baseProduct"
                    variant="underlined"
                    data-cy="input-base-product"
                    :loading="loadingGenerateData"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col class="shrink" cols="12" md="2">
                  <v-btn class="btn-orange" :disabled="!currentItem.baseProduct" @click="handleGenerateClick" data-cy="generate-button">Generate</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" v-if="currentItem.type == 'assembly'">
              <v-row class="d-flex align-center">
                <v-col>
                  <v-autocomplete
                    label="Root Module"
                    :items="availableComponents"
                    v-if="assemblyBaseProduct == 'component'"
                    v-model="rootModule"
                    variant="underlined"
                    item-title="name"
                    item-value="id"
                    return-object
                  ></v-autocomplete>
                  <v-autocomplete
                    label="Root Material"
                    :items="availableMaterials"
                    v-if="assemblyBaseProduct == 'material'"
                    v-model="rootModule"
                    variant="underlined"
                    item-title="name"
                    item-value="id"
                    return-object
                  ></v-autocomplete>
                </v-col>

                <v-col class="shrink">
                  <v-btn class="btn-orange" :disabled="!rootModule" @click="handleGenerateClick" data-cy="generate-button">Generate</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text class="v-card-wrapper" v-if="(currentItem.type == 'blueprint' || currentItem.type == 'assembly') && treeData.length">
        <div class="wrapper">
          <div class="wrapper-header"><h4>Structure</h4></div>
          <div class="wrapper-content">
            <v-row>
              <v-col cols="12" md="5" class="left-side">
                <div v-if="loadingStructureData" class="loading-spinner" style="display: flex; justify-content: center; align-items: center; height: 100%">
                  <v-progress-circular indeterminate color="orange"></v-progress-circular>
                </div>
                <div v-else class="structure-container">
                  <n-space vertical>
                    <n-tree :show-line="true" :default-expanded-keys="defaultExpandedKeys" :data="treeData" :node-props="nodeProps" />
                  </n-space>
                </div>
              </v-col>

              <v-col cols="12" md="7">
                <div class="details-container">
                  <v-row>
                    <v-col md="4">
                      <div>
                        Module name:
                        <br />
                        <div style="margin-top: -14px">
                          <!-- <v-text-field v-model="itemDetails.name" variant="underlined" data-cy="input-module-name" hide-details :disabled="!itemDetails?.name" /> -->
                          <v-text-field v-model="moduleItemName" variant="underlined" data-cy="input-module-name" hide-details :disabled="!itemDetails" />
                        </div>
                      </div>
                    </v-col>
                    <v-col md="4">
                      Module type:
                      <br />
                      <div style="font-weight: 500; font-size: 16px; margin-top: 4px">{{ itemDetails?.componentTypeName }}</div>
                    </v-col>
                    <v-col md="4">
                      <!-- <v-btn style="margin-bottom: 6px; width: 84%" class="btn-orange" @click="reGenerate" data-cy="formula-btn">Update</v-btn>
                      <v-btn
                        style="width: 84%"
                        v-if="itemDetails?.componentTypeName.includes('Branch')"
                        class="btn-orange"
                        @click="branchModal(itemDetails)"
                        data-cy="re-generate-btn"
                      >
                        Add Furcation
                      </v-btn> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-btn style="margin-bottom: 6px; width: 84%" class="btn-orange" @click="reGenerate" data-cy="formula-btn">Update</v-btn></v-col>
                    <v-col>
                      <v-btn
                        style="width: 84%"
                        :disabled="!itemDetails?.componentTypeName.includes('Branch')"
                        class="btn-orange"
                        @click="branchModal(itemDetails)"
                        data-cy="re-generate-btn"
                      >
                        Add Furcation
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="!itemDetails?.componentTypeName.includes('Breakout')"
                        style="width: 84%"
                        class="btn-orange"
                        @click="editBranchesModal(itemDetails)"
                        data-cy="edit-branches-btn"
                      >
                        Edit Breakout
                      </v-btn>
                    </v-col>
                  </v-row>
                  <br />

                  <div class="text-details" data-cy="details-content">
                    <div>
                      <v-row>
                        <v-col cols="12" md="12" style="padding-bottom: 0px">
                          <v-card-text class="section-item">
                            <v-toolbar flat class="section-title">
                              <v-toolbar-title>Contains</v-toolbar-title>
                              <v-col class="d-flex align-center" cols="auto">
                                <v-autocomplete
                                  label="Select Logic Module"
                                  :items="availableComponents"
                                  v-model="selectedComponentType"
                                  variant="underlined"
                                  data-cy="select-component-type"
                                  clearable
                                  style="width: 200px"
                                  item-title="name"
                                  return-object
                                  :menu-props="{ maxHeight: '250px' }"
                                ></v-autocomplete>
                                <v-btn class="btn-orange ml-2" @click="addComponentToContainsList" data-cy="add-contains-btn">
                                  <v-icon left>mdi-plus</v-icon>
                                  Add
                                </v-btn>
                              </v-col>
                            </v-toolbar>

                            <v-table density="compact">
                              <template v-slot:default>
                                <thead style="background-color: #f8f8f8">
                                  <tr>
                                    <th style="min-width: 130px">Name</th>
                                    <th class="text-end">Quantity</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(component, index) in containsList" :key="index">
                                    <td>{{ component.name }}</td>
                                    <td>
                                      <v-text-field v-model="component.quantity" variant="underlined" data-cy="quantity-input" density="compact" reverse />
                                    </td>
                                    <td class="text-center">
                                      <span @click="removeComponentFromContainsList(index)">
                                        <i class="fa-duotone fa-solid fa-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-table>
                          </v-card-text>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="12" style="padding-bottom: 0px">
                          <v-card-text class="section-item">
                            <v-toolbar flat class="section-title">
                              <v-toolbar-title>Attributes</v-toolbar-title>
                              <v-col class="d-flex align-center" cols="auto">
                                <v-autocomplete
                                  label="Select Attribute"
                                  :items="availableAttributesTrimmed"
                                  v-model="selectedAttribute"
                                  variant="underlined"
                                  data-cy="select-attribute"
                                  clearable
                                  style="width: 200px"
                                  item-title="name"
                                  return-object
                                  :menu-props="{ maxHeight: '250px' }"
                                ></v-autocomplete>
                                <v-btn class="btn-orange ml-2" @click="addAttributeWithoutValue" data-cy="add-attributes-btn">
                                  <v-icon left>mdi-plus</v-icon>
                                  Add
                                </v-btn>
                              </v-col>
                            </v-toolbar>

                            <v-table density="compact">
                              <template v-slot:default>
                                <thead style="background-color: #f8f8f8">
                                  <tr>
                                    <th style="min-width: 130px">Name</th>
                                    <th>Value</th>
                                    <th>UoM</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(attr, index) in itemDetails?.attributeValues" :key="index">
                                    <td>{{ attr.description }}</td>

                                    <td>
                                      <div>
                                        <template v-if="findAvailableAttributeById(attr.attributeId)?.values?.length">
                                          <v-combobox
                                            v-model="attr.value"
                                            :items="findAvailableAttributeById(attr.attributeId)?.values"
                                            variant="underlined"
                                            item-title="value"
                                            item-value="value.value"
                                            data-cy="enum-select"
                                            density="compact"
                                            @update:modelValue="
                                              (selected) => {
                                                attr.value = selected?.value;
                                                markAsModified(attr);
                                              }
                                            "
                                          ></v-combobox>
                                        </template>

                                        <template v-else>
                                          <v-text-field
                                            v-model="attr.value"
                                            variant="underlined"
                                            data-cy="enum-description-input"
                                            @update:modelValue="markAsModified(attr)"
                                            density="compact"
                                            reverse
                                          ></v-text-field>
                                        </template>
                                      </div>
                                    </td>

                                    <td>
                                      {{ getUomNameById(findAvailableAttributeById(attr.attributeId)?.uomId) }}
                                    </td>

                                    <td class="text-center">
                                      <span :class="{ 'disabled-btn': !attr.isUserAdded }" @click="removeAttribute(index)">
                                        <i class="fa-duotone fa-solid fa-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-table>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>

      <AssembliesModalValues
        v-if="currentItem.type == 'values'"
        :currentItem="currentItem"
        :availableAttributes="availableAttributes"
        v-model="selectedAttribute"
        :itemAttributes="itemAttributes"
      />

      <AssembliesEditBreakoutModal
        v-model:isModalOpen="editBreakoutDataModalDialog"
        :breakout="breakoutData"
        :availableAttributes="availableAttributes"
        :breakoutAdditionalData="breakoutAdditionalData"
        @close="closeBranchModal"
        @save="saveBranchData"
      />

      <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="close" data-cy="cancel-btn">Cancel</v-btn>
        <v-btn class="btn-save" :disabled="!isFormValid" @click="save" data-cy="save-btn">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <Dialogs :cancelDialog="cancelDialog" @closeCancelDialog="closeCancelDialog" @confirmCancel="confirmCancel" />

  <!-- Generate Confirmation Dialog -->
  <v-dialog v-model="generateDialog" max-width="400px" data-cy="generate-dialog">
    <v-card data-cy="generate-dialog-card">
      <v-card-title class="headline" data-cy="generate-dialog-title">Confirm Action</v-card-title>
      <v-card-text data-cy="generate-dialog-text">Update Data? All current data will be lost. Do you want to continue?</v-card-text>
      <v-card-actions data-cy="generate-dialog-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="closeGenerateDialog" data-cy="generate-no-button">No</v-btn>
        <v-btn class="btn-delete" @click="confirmGenerate" data-cy="generate-yes-button">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="branchModalDialog" max-width="1100px">
    <v-card>
      <v-card-title class="headline">Insert Branches</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-slider
              v-model="bmd_branch_count"
              :min="1"
              :max="20"
              :step="1"
              :label="`Select branch count: ${Math.ceil(bmd_branch_count)}`"
              tick-size="10"
              data-cy="branch-count-slider"
            ></v-slider>
          </v-col>
        </v-row>
        <br />
        <hr />
        <br />
        <v-row>
          <v-col v-for="(connectorCount, index) in bmd_connectors_count" :key="index" cols="12" md="4">
            <v-text-field v-model="bmd_connectors_count[index]" :label="`Connectors Count Branch ${index + 1}`" variant="outlined" data-cy="connectors-count"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="branchModalDialog = false">Cancel</v-btn>
        <v-btn class="btn-orange" @click="handleBranchModalSubmit">Insert</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { VTreeview } from 'vuetify/labs/VTreeview';
  import { MaterialItem } from '@/types/components';
  import { AttributeItem, AssemblyItem, ComponentItem } from '@/types/assembly';
  import Dialogs from '@/components/Dialogs.vue';
  import assemblyService from '@/services/assemblyService';
  import AssembliesModalValues from './AssembliesModalValues.vue';
  import AssembliesEditBreakoutModal from './AssembliesEditBreakoutModal.vue';

  const BRANCH_TYPE_ID = '66f93b60530fba005e6f5c86';
  const BREAKOUT_TYPE_ID = '66cdda1e5f893788612b3c44';
  const FURCATION_TYPE_ID = '66fae2a266978e5c7a25c3c5';
  const TRANSITION_TYPE_ID = '66f9849f530fba005e70ac88';
  const CONNECTOR_COUNT_ATTR_ID = '66e03e0bd8c004068df51785';

  export default defineComponent({
    components: {
      VTreeview,
      Dialogs,
      AssembliesModalValues,
      AssembliesEditBreakoutModal,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      currentItem: {
        type: Object as PropType<AssemblyItem>,
        required: true,
        default: () => ({
          id: '',
          type: null,
          name: '',
          description: '',
          code: null,
          components: [],
        }),
      },
      availableAttributes: {
        type: Array as PropType<AttributeItem[]>,
        required: true,
        default: () => [],
      },
      availableComponents: {
        type: Array as PropType<ComponentItem[]>,
        required: true,
        default: () => [],
      },
      availableMaterials: {
        type: Array as PropType<MaterialItem[]>,
        required: true,
        default: () => [],
      },
      unitOptions: {
        type: Array as PropType<any[]>,
        required: true,
        default: () => [],
      },
    },
    emits: ['close', 'save'],
    data() {
      return {
        isModalOpen: true,
        cancelDialog: false,
        generateDialog: false,
        branchModalDialog: false,
        branchModalData: {
          attributeValues: [] as { description: string; attributeId: string; value: string | number }[],
          children: [] as any[],
        },
        bmd_branch_count: 2,
        bmd_connectors_count: [2, 2],
        loadingGenerateData: false,
        loadingStructureData: false,
        newType: null,
        selectedType: this.currentItem.type,
        typeOptions: [
          { label: 'Blueprint', value: 'blueprint' },
          { label: 'Sub-Assembly', value: 'assembly' },
          { label: 'Code Values', value: 'values' },
        ] as { label: string; value: string }[],
        selectedItem: null as any,
        itemDetails: null as any,
        selectedAttribute: null as any,
        itemAttributes: { attributeValues: [] } as any,
        selectedComponentType: null as any,
        containsList: [] as any[],
        rootModule: null as any,
        assemblyBaseProduct: 'component',
        assemblyBaseProductOptions: [
          { label: 'Modules', value: 'component' },
          { label: 'Raw Materials', value: 'material' },
        ] as { label: string; value: string }[],
        defaultExpandedKeys: [] as any[],
        treeData: [] as any[],
        selected_item: null as any,
        breakoutData: [] as any[],
        breakoutAdditionalData: [] as any[],
        editBreakoutDataModalDialog: false,
        editBreakoutData: null as any,
        lastClickedDepth: null,
      };
    },
    mounted() {
      if (this.title === 'Edit Assembly') {
        if (this.currentItem.type !== 'values') {
          this.generateTreeItems();
          const id = this.currentItem.component?.componentTypeId;
          const component = this.availableComponents.find((component) => component.id === id);
          this.rootModule = component?.name;
        } else if (this.currentItem.type === 'values') {
          this.generateAttributeItems();
        }
      }
    },
    watch: {
      bmd_branch_count(newCount) {
        this.reCalculateBranchConnectorsCount(newCount);
      },
    },
    computed: {
      availableAttributesTrimmed() {
        return this.availableAttributes.map((component) => ({
          ...component,
          name: component.name.length > 25 ? component.name.substring(0, 25) + '...' : component.name,
        }));
      },
      isFormValid() {
        if (!this.currentItem.name || !this.selectedType) {
          return false;
        }
        if (this.currentItem.type === 'assembly' && !this.rootModule) {
          return false;
        }
        if (this.currentItem.type === 'blueprint' && (!this.currentItem.code || !this.currentItem.baseProduct)) {
          return false;
        }
        if (this.currentItem.type === 'values' && !this.currentItem.code) {
          return false;
        }
        return true;
      },
      moduleItemName: {
        get() {
          return this.itemDetails ? this.itemDetails.name : '';
        },
        set(value: string) {
          if (this.itemDetails) {
            this.itemDetails.name = value;
          }
        },
      },
    },
    methods: {
      handleClose() {
        if (this.hasChanges()) {
          this.cancelDialog = true;
        } else {
          this.closeModal();
        }
      },
      handleGenerateClick() {
        if (this.treeData.length > 0) {
          this.generateDialog = true;
        } else {
          if (this.currentItem.type === 'blueprint') {
            this.generateBasePN(this.currentItem.baseProduct);
          } else if (this.currentItem.type === 'assembly') {
            this.generateStructure(this.rootModule.id);
          }
        }
      },
      handleTypeChange(newTypeValue: any) {
        this.newType = newTypeValue;
        this.confirmTypeChangeWithDialog();
      },
      branchModal(item: any) {
        this.branchModalDialog = true;
        this.branchModalData = item;
        this.reCalculateBranchConnectorsCount(this.bmd_branch_count);
      },
      reCalculateBranchConnectorsCount(branchCount: number) {
        const connectorsCount = this.branchModalData.attributeValues.find(({ attributeId }) => attributeId === CONNECTOR_COUNT_ATTR_ID)?.value;

        if (connectorsCount) {
          const amount = Math.floor(Number(connectorsCount) / branchCount);
          this.bmd_connectors_count = Array(branchCount).fill(amount);
        } else {
          this.bmd_connectors_count = [];
        }
      },
      handleBranchModalSubmit() {
        if (this.bmd_branch_count < 1) {
          alert('Branch count must be at least 1.');
          return;
        }

        this.branchModalData.children = [
          ...this.branchModalData.children.filter(
            ({ componentTypeName, componentTypeId }) => componentTypeName.toLowerCase().includes('label') || componentTypeId === FURCATION_TYPE_ID
          ),
          {
            componentTypeId: BREAKOUT_TYPE_ID,
            children: [
              {
                componentTypeId: TRANSITION_TYPE_ID,
                children: [],
              },
              ...this.bmd_connectors_count.map((value) => ({
                componentTypeId: BRANCH_TYPE_ID,
                attributeValues: [
                  {
                    value,
                    attributeId: CONNECTOR_COUNT_ATTR_ID,
                    description: 'Connectors Count',
                  },
                ],
                children: [],
              })),
            ],
          },
        ];

        this.branchModalDialog = false;
        this.reGenerate();
      },
      confirmTypeChangeWithDialog() {
        if ((this.currentItem.type !== undefined && this.treeData.length > 0) || this.itemAttributes.attributeValues.length > 0) {
          this.cancelDialog = true;
        } else {
          this.confirmCancel();
        }
      },
      confirmGenerate() {
        this.clearGenerationData();
        if (this.currentItem.type === 'blueprint') {
          this.generateBasePN(this.currentItem.baseProduct);
        } else if (this.currentItem.type === 'assembly') {
          this.generateStructure(this.rootModule.id);
        }
        this.closeGenerateDialog();
      },
      confirmCancel() {
        this.currentItem.type = this.newType;
        this.clearSectionData();
        this.treeData = [];
        this.cancelDialog = false;
      },
      closeCancelDialog() {
        this.selectedType = this.currentItem.type;
        this.newType = null;
        this.cancelDialog = false;
      },
      closeGenerateDialog() {
        this.generateDialog = false;
      },
      closeModal() {
        this.isModalOpen = false;
        this.$emit('close');
      },
      close() {
        this.handleClose();
      },
      clearSectionData() {
        this.itemDetails = null;
        this.selectedAttribute = null;
        this.itemAttributes = { attributeValues: [] };
        this.selectedComponentType = null;
        this.containsList = [];
        this.rootModule = null;
        this.currentItem.baseProduct = '';
        this.currentItem.code = '';
      },
      async generateBasePN(baseProduct?: string) {
        this.loadingGenerateData = true;
        this.treeData = [];
        this.itemDetails = null;

        if (baseProduct?.length) {
          try {
            const response = await assemblyService.generateAssemblyWithoutMaterials(baseProduct);
            if (response) {
              const data = response.tree;
              this.treeData = this.convertToNaiveTree([data]);
              console.log('Generated Response:', data);
            }
          } catch (error: any) {
            const message = error.response?.data?.message || error.message || 'An unknown error occurred';
            this.$log.showError(`Failed fetch data: ${message}`);
          }
        }
        this.loadingGenerateData = false;
      },

      async generateStructure(rootModuleId?: string) {
        this.loadingGenerateData = true;
        this.treeData = [];
        this.itemDetails = null;

        if (rootModuleId) {
          try {
            if (this.assemblyBaseProduct === 'component') {
              const response = await assemblyService.generateComponent(rootModuleId);

              if (response) {
                const data = response.tree;
                this.treeData = this.convertToNaiveTree([data]);
              }
            } else if (this.assemblyBaseProduct === 'material') {
              const response = await assemblyService.generateMaterial(rootModuleId);
              if (response) {
                const data = response.tree;
                this.treeData = this.convertToNaiveTree([data]);
              }
            }
          } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
            // Handle error appropriately
          }
        }
        this.loadingGenerateData = false;
      },
      generateTreeItems() {
        const loadedTree = this.currentItem.component;

        const findComponentById = (id: string) => {
          return this.availableComponents.find((component) => component.id === id);
        };

        const findAttributeById = (id: string) => {
          return this.availableAttributes.find((attribute) => attribute.id === id);
        };

        const depthCounters = new Map();

        const generateTree = (tree: any, depthLevel: number = 0) => {
          const component = findComponentById(tree.componentTypeId);
          const generateRandomId = () => Math.random().toString(36).substring(7);

          if (!depthCounters.has(depthLevel)) {
            depthCounters.set(depthLevel, 1);
          }
          const elementNumber = depthCounters.get(depthLevel);
          depthCounters.set(depthLevel, elementNumber + 1);
          return {
            id: generateRandomId(),
            id_orig: tree.id,
            componentTypeId: tree.componentTypeId,
            name: tree?.name,
            componentTypeName: tree.componentTypeName || component?.name || 'Unknown component TypeName',
            depth: `${depthLevel}-${elementNumber}`,
            children: tree.children ? tree.children.map((child: any) => generateTree(child, depthLevel + 1)) : [],
            attributeValues: tree.attributeValues
              ? tree.attributeValues.map((attr: any) => {
                  const attribute = findAttributeById(attr.attributeId);
                  return {
                    id: generateRandomId(),
                    attributeId: attr.attributeId,
                    description: attribute?.name || 'Unknown Attribute',
                    value: attr.value,
                    isUserAdded: false,
                    isUserModified: attr.isUserModified || false,
                  };
                })
              : [],
          };
        };

        this.treeData = this.convertToNaiveTree([generateTree(loadedTree)]);
        if (this.treeData.length > 0) {
          this.handleTitleClick(this.treeData[0]);
        }
      },
      generateAttributeItems() {
        const loadedAttributes = this.currentItem.component?.attributeValues || [];

        if (!loadedAttributes.length) {
          this.itemAttributes = { attributeValues: [] };
          return;
        }

        const findAttributeById = (id: string) => {
          return this.availableAttributes.find((attribute) => attribute.id === id);
        };

        this.itemAttributes = {
          attributeValues: loadedAttributes.map((attr: any) => {
            const attribute = findAttributeById(attr.attributeId);

            return {
              id: attr.attributeId,
              attributeId: attr.attributeId,
              description: attribute?.name || 'Unknown Attribute',
              uom: attribute?.unit || '',
              value: attr.value || '',
              isUserAdded: false,
              isUserModified: attr.isUserModified || false,
            };
          }),
        };
      },
      // populateContainsList(componentTypeId: string) {
      //   const component = this.availableComponents.find((component) => component.id === componentTypeId);
      //   if (!component) return;

      //   const contains = component.contains;
      //   this.containsList = contains.map((item) => {
      //     const comp = this.availableComponents.find((component) => component.id === item.componentTypeId);
      //     return {
      //       id: item.id,
      //       componentTypeId: item.componentTypeId,
      //       name: comp?.name || 'Unknown Component',
      //       quantity: item.quantity,
      //       isUserAdded: false,
      //       isUserModified: false,
      //     };
      //   });
      // },
      populateContainsList(componentTypeIds: string[]) {
        this.containsList = [];

        componentTypeIds.forEach((componentTypeId) => {
          const component = this.availableComponents.find((comp) => comp.id === componentTypeId);

          if (component) {
            // if (!this.containsList.some((existing) => existing.componentTypeId === componentTypeId)) {
            this.containsList.push({
              id: component.id,
              componentTypeId: component.id,
              name: component.name || 'Unknown Component',
              quantity: 1,
              isUserAdded: false,
              isUserModified: false,
            });
            // }
          }
        });
      },
      prepareDataForSave() {
        interface ChildComponentData {
          componentTypeId: string;
          name?: string;
          componentTypeName?: string;
          attributeValues: { attributeId: string; value: any; isUserModified: boolean }[];
          contains: { componentTypeId: string; quantity: number }[];
          children: ChildComponentData[];
        }

        const generateChildrenData = (children: any[]): ChildComponentData[] => {
          return children.map(
            (child: any): ChildComponentData => ({
              name: child.name,
              componentTypeId: child.componentTypeId,
              attributeValues:
                child.attributeValues
                  ?.filter(({ value }: { value: unknown }) => !!value)
                  .map((attr: any) => ({
                    attributeId: attr.attributeId,
                    value: attr.value,
                    isUserModified: attr.isUserModified ? true : undefined,
                  })) || [],
              contains: this.containsList.map((contain) => ({
                componentTypeId: contain.componentTypeId,
                quantity: contain.quantity,
              })),
              children: generateChildrenData(child.children || []),
            })
          );
        };

        let dataToSave: any = {
          name: this.currentItem.name || '',
          description: this.currentItem.description || '',
          code: this.currentItem.code || '',
        };

        if (this.currentItem.type === 'blueprint') {
          const rootComponent = this.treeData.length > 0 ? this.treeData[0] : null;
          if (!rootComponent) {
            console.error('No root component found in treeItemsGenerated.');
            return null;
          }

          const component = {
            componentTypeId: rootComponent.componentTypeId || rootComponent.id_orig,
            attributeValues: rootComponent.attributeValues.map((attr: any) => ({
              attributeId: attr.attributeId,
              value: attr.value || 'N/A',
            })),
            contains: this.containsList.map((contain) => ({
              componentTypeId: contain.componentTypeId,
              quantity: contain.quantity,
            })),
            children: generateChildrenData(rootComponent.children || []),
          };

          dataToSave = {
            ...dataToSave,
            type: this.currentItem.type || '',
            baseProduct: this.currentItem.baseProduct || '',
            component: component,
          };
        } else if (this.currentItem.type === 'assembly') {
          const rootComponent = this.treeData.length > 0 ? this.treeData[0] : null;
          if (!rootComponent) {
            console.error('No root component found in treeItemsGenerated.');
            return null;
          }

          const component = {
            componentTypeId: rootComponent.componentTypeId || rootComponent.id_orig,
            attributeValues: rootComponent.attributeValues.map((attr: any) => ({
              attributeId: attr.attributeId,
              value: attr.value || 'N/A',
            })),
            contains: this.containsList.map((contain) => ({
              componentTypeId: contain.componentTypeId,
              quantity: contain.quantity,
            })),
            children: generateChildrenData(rootComponent.children || []),
          };

          dataToSave = {
            ...dataToSave,
            type: this.currentItem.type || '',
            rootModule: this.rootModule || '',
            component: component,
          };
        } else if (this.currentItem.type === 'values') {
          const attributeValues = this.itemAttributes.attributeValues.map((attr: any) => ({
            attributeId: attr.attributeId,
            value: attr.value,
          }));

          dataToSave = {
            ...dataToSave,
            type: this.currentItem.type || '',
            component: {
              attributeValues: attributeValues,
              children: [],
            },
          };
        }

        return dataToSave;
      },

      save() {
        if (!this.isFormValid) {
          return;
        }
        const preparedData = this.prepareDataForSave();
        this.$emit('save', preparedData);
        this.closeModal();
      },
      async reGenerate() {
        this.loadingStructureData = true;
        const preparedData = this.prepareDataForSave();

        if (!preparedData) {
          return;
        }

        try {
          const response = await assemblyService.reGenerateAssembly(preparedData);
          //console.log('Re-Generated Response:', response.data);
          if (response && response.data && response.data.component) {
            this.currentItem.component = response.data.component;
            this.generateTreeItems();
            this.expandNodeByDepth(this.lastClickedDepth);
            this.loadingStructureData = false;
          } else {
            this.loadingStructureData = false;
            this.$log.showError(`Error during re-generate:: ${response}`);
          }
        } catch (error: any) {
          this.loadingStructureData = false;
          const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
          this.$log.showError(`Error during re-generate:: ${errorMessage}`);
        }
      },

      markAsModified(attr: any) {
        attr.isUserModified = true;
      },

      addComponentToContainsList() {
        if (!this.selectedComponentType) {
          return;
        }

        const componentToAdd = {
          id: Math.random().toString(36).substring(7),
          componentTypeId: this.selectedComponentType.id,
          name: this.selectedComponentType.name,
          quantity: 1,
          isUserAdded: true,
          isUserModified: false,
        };

        this.containsList.push(componentToAdd);

        if (!this.itemDetails.children) {
          this.itemDetails.children = [];
        }

        const attributeValues = this.selectedComponentType.attributes.map((attr: any) => {
          const attribute = this.findAvailableAttributeById(attr.attributeId);
          return {
            id: Math.random().toString(36).substring(7),
            attributeId: attr.attributeId,
            description: attribute?.name || 'Unknown Attribute',
            uom: attribute?.unit || '',
            value: null,
            isUserAdded: false,
            isUserModified: attr.isUserModified || false,
          };
        });

        this.itemDetails.children.push({
          id: componentToAdd.id,
          componentTypeId: this.selectedComponentType.id,
          componentTypeName: this.selectedComponentType.name,
          quantity: componentToAdd.quantity,
          children: [],
          attributeValues: attributeValues,
        });

        this.selectedComponentType = null;
        //   this.treeData = this.convertToNaiveTree([this.treeData[0]]);
        this.reGenerate();
      },
      removeComponentFromContainsList(index: number) {
        const removedComponent = this.containsList.splice(index, 1)[0];

        if (this.itemDetails?.children) {
          const childIndex = this.itemDetails.children.findIndex((child: any) => child.componentTypeId === removedComponent.componentTypeId);

          if (childIndex !== -1) {
            this.itemDetails.children.splice(childIndex, 1);
          }
        }
      },
      addAttribute() {
        if (!this.selectedAttribute) return;

        const attribute = {
          id: Math.random().toString(36).substring(7),
          attributeId: this.selectedAttribute.id,
          description: this.selectedAttribute.name,
          uom: this.selectedAttribute.unit,
          value: null,
        };
        this.selectedAttribute = null;

        this.itemAttributes.attributeValues.push(attribute);
      },
      addAttributeWithoutValue() {
        if (!this.selectedAttribute) return;

        const attribute = {
          id: Math.random().toString(36).substring(7),
          attributeId: this.selectedAttribute.id,
          description: this.selectedAttribute.name,
          uom: this.selectedAttribute.unit,
          value: null,
          isUserAdded: true,
          isUserModified: false,
        };
        this.selectedAttribute = null;

        this.itemDetails.attributeValues.push(attribute);
      },
      removeAttribute(index: number) {
        this.itemDetails?.attributeValues.splice(index, 1);
      },
      clearGenerationData() {
        this.containsList = [];
        this.selectedComponentType = null;
        this.itemAttributes = { attributeValues: [] };
        this.itemDetails = null;
        this.selectedAttribute = null;
      },
      hasChanges() {
        return JSON.stringify(this.currentItem) !== JSON.stringify(this.$props.currentItem);
      },
      findAvailableAttributeById(attributeId: string) {
        return this.availableAttributes.find((attr) => attr.id === attributeId);
      },
      getUomNameById(uomId: any) {
        for (const category of this.unitOptions) {
          const unit = category.value?.find((u: any) => u.id === uomId);
          if (unit) {
            return unit.name;
          }
        }
        return '';
      },
      convertToNaiveTree(vuetifyItems: any[]): any[] {
        return vuetifyItems.map((item) => {
          const node: any = {
            key: item.id,
            id_orig: item.id_orig,
            label: item.name ? `${item.name} (${item.componentTypeName})` : item.componentTypeName || 'Unknown Component',
            ...item,
          };
          if (node.label.includes('Branch')) {
            node.label += ` ⚙️`;
          }

          if (item.children && item.children.length > 0) {
            node.children = this.convertToNaiveTree(item.children);
          }
          if (node.children && node.children.length === 0) {
            delete node.children;
          }

          return node;
        });
      },
      nodeProps({ option }: { option: any }) {
        return {
          onClick: () => {
            this.handleTitleClick(option);
            this.lastClickedDepth = option.depth;
            //console.log('Clicked Node:', this.lastClickedDepth);
            // if (!this.defaultExpandedKeys.includes(option.key)) {
            //   this.defaultExpandedKeys.push(option.key);
            // }
            // else {
            //   this.defaultExpandedKeys = this.defaultExpandedKeys.filter((key: any) => key !== option.key);
            // }
          },
          onContextmenu: (event: MouseEvent) => {
            //event.preventDefault();
          },
          class: {
            'selected-node': this.selected_item && this.selected_item.key === option.key,
            'bold-node': this.selected_item && this.selected_item.key === option.key,
          },
        };
      },
      // handleTitleClick(item: any) {
      //   console.log('Click Item', item);
      //   this.itemDetails = item;
      //   // this.populateContainsList(item.componentTypeId);
      //   const componentTypeIds = item.children.map((child: any) => child.componentTypeId);
      //   this.populateContainsList(componentTypeIds);

      //   console.log('Contains List', componentTypeIds);
      //   if (this.selected_item && this.selected_item.key === item.key) {
      //     return;
      //   }
      //   this.selected_item = item;
      // },
      handleTitleClick(item: any) {
        console.log('Clicked Item:', item);

        this.itemDetails = item;

        if (item.children && Array.isArray(item.children)) {
          const componentTypeIds = item.children.filter((child: any) => child.componentTypeId).map((child: any) => child.componentTypeId);

          this.populateContainsList(componentTypeIds);
        } else {
          this.populateContainsList([]);
        }

        if (!this.selected_item || this.selected_item.key !== item.key) {
          this.selected_item = item;
        }
      },

      editBranchesModal(item: any) {
        const addAdditionalData = [this.addAdditionalBranchesData(item)];

        this.breakoutAdditionalData = addAdditionalData;
        this.breakoutData = item;
        this.editBreakoutDataModalDialog = true;
      },
      // saveBranchData(data: any) {
      //   this.breakoutData = data;
      //   this.editBreakoutDataModalDialog = false;
      // },

      addAdditionalBranchesData(data: any): { end: string } {
        const endOneId = '66cc55a245a494cd8e561f36'; // ID for END 1
        const treeData = this.treeData;
        const curID = data.id;

        const findElementAndParentChain = (tree: any[], targetID: string, parentChain: any[] = []): { element: any; parentChain: any[] } | null => {
          for (const node of tree) {
            if (node.id === targetID) {
              return { element: node, parentChain };
            }

            if (node.children && node.children.length > 0) {
              const result = findElementAndParentChain(node.children, targetID, [...parentChain, node]);
              if (result) return result;
            }
          }
          return null;
        };

        const result = findElementAndParentChain(treeData, curID);

        if (result) {
          const { parentChain } = result;

          const matchingParent = parentChain.find((parent) => parent.componentTypeId === endOneId);

          if (matchingParent) {
            return { end: 'End 1' };
          }
        }
        return { end: 'End 2' };
      },
      saveBranchData(data: any) {
        const findAndReplaceByKey = (nodeList: any[], key: string, newData: any) => {
          for (let i = 0; i < nodeList.length; i++) {
            const node = nodeList[i];
            if (node.key === key) {
              nodeList[i] = { ...node, ...newData };
              return true;
            }
            if (node.children && node.children.length > 0) {
              const found = findAndReplaceByKey(node.children, key, newData);
              if (found) return true;
            }
          }
          return false;
        };

        const targetKey = data.key || (data[0] && data[0].key);
        if (targetKey) {
          findAndReplaceByKey(this.treeData, targetKey, data);
          this.reGenerate();
          this.editBreakoutDataModalDialog = false;
        } else {
          console.error('Data does not contain a valid key');
        }
      },

      closeBranchModal() {
        this.editBreakoutDataModalDialog = false;
      },
      expandNodeByDepth(targetDepth: any) {
        const findNodeByDepth = (nodes: any) => {
          for (const node of nodes) {
            if (node.depth === targetDepth) {
              this.defaultExpandedKeys = [node.key];
              this.handleTitleClick(node);
              return true;
            }
            if (node.children && findNodeByDepth(node.children)) {
              this.defaultExpandedKeys.push(node.key);
              return true;
            }
          }
          return false;
        };
        findNodeByDepth(this.treeData);
        this.$nextTick(() => {
          this.scrollToSelectedNode();
        });
      },
      scrollToSelectedNode() {
        const container = document.querySelector('.structure-container');
        const selectedNode = container?.querySelector('.selected-node');

        if (container && selectedNode) {
          const containerTop = container.getBoundingClientRect().top;
          const nodeTop = selectedNode.getBoundingClientRect().top;

          const offset = nodeTop - containerTop - container.clientHeight / 2;
          container.scrollTop += offset;
        }
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
  div:deep(td) {
    border: none !important;
  }
  div:deep(.v-input__details) {
    padding-top: 0px !important;
    grid-area: auto !important;
  }
  .formula-btn {
    text-transform: none;
  }
  .copy-btn {
    cursor: pointer;
    margin-left: 5px;
  }
  .copy-btn:hover {
    color: orange;
  }
  .v-card-wrapper {
    padding: 0px;
  }
  .wrapper {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 0px;
    /* margin-top: 10px; */
  }
  .wrapper-header {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .wrapper-content {
    padding: 10px;
  }
  .custom-title {
    cursor: pointer;
    font-weight: bold;
  }
  .fa-trash {
    cursor: pointer;
  }
  .fa-trash:hover {
    color: red;
  }

  .disabled-btn {
    cursor: not-allowed;
    color: #ccc;
    pointer-events: none;
  }

  :deep(.selected-node > div) {
    font-weight: bold;
  }

  :deep(.n-tree .n-tree-node-indent.n-tree-node-indent--show-line::before) {
    border-left: 1px solid rgb(160, 160, 160);
  }
  .left-side {
    position: relative;
    border-right: 1px solid #e0e0e0;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .structure-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: auto;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 10px;
  }
  .section-item {
    padding: 0px 0px 8px 0px;
    border: 1px solid #e0e0e0;
  }
  :deep(.n-tree .n-tree-node-content) {
    white-space: nowrap;
  }
  table tr:hover {
    background-color: #f5f5f5;
  }
</style>
